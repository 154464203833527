import styled from "styled-components"

import { FormControlLabel, Radio, TextField } from "@material-ui/core"
import { FormikProps } from "formik"

import {
  CreateOrganizationStepWrapper,
  RadioButtonsWrapper,
} from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/organizationCreateWizardStyles"
import { breakpoint } from "src/constants/breakpoints"
import { TProfilingRoles } from "src/data/analytics/types/analyticsTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"

export type TOrganizationRoleForm = {
  profilingRole: TProfilingRoles | null
  customProfilingRole: string
}

export type TRoleRadioButton = {
  value: TProfilingRoles
  label: string
}

export const ORG_ROLE_FORM_ID = "org-role-form"

export function OrganizationRoleStep({
  formData,
  options,
  isError = false,
}: {
  formData: FormikProps<TOrganizationRoleForm>
  options: {
    left: TRoleRadioButton[]
    right: TRoleRadioButton[]
  }
  isError?: boolean
}) {
  const { t } = useTranslate()

  function renderRadiobuttons(radioButtons: TRoleRadioButton[]) {
    return radioButtons.map((radioButton) => (
      <div key={radioButton.value}>
        <FormControlLabel
          value={radioButton.value}
          control={<Radio />}
          label={radioButton.label}
        />
        {radioButton.value === "Other" && (
          <TextFieldWrapper $hidden={formData.values.profilingRole !== "Other"}>
            <TextField
              name="customProfilingRole"
              value={formData.values.customProfilingRole}
              onChange={formData.handleChange}
              placeholder={`${t(langKeys.create_organization_role_custom_placeholder)} (${t(langKeys.input_optional)})`}
              fullWidth
            />
          </TextFieldWrapper>
        )}
      </div>
    ))
  }

  return (
    <CreateOrganizationStepWrapper
      id={ORG_ROLE_FORM_ID}
      onSubmit={formData.handleSubmit}
      noValidate
    >
      <MText variant="heading2">
        {t(langKeys.create_organization_what_is_your_role)}
      </MText>

      <RadioButtonsWrapper
        name="profilingRole"
        value={formData.values.profilingRole}
        onChange={(e) => {
          formData.handleChange(e)
          formData.setFieldValue("customProfilingRole", "")
        }}
      >
        <div>{renderRadiobuttons(options.left)}</div>
        <div>{renderRadiobuttons(options.right)}</div>
      </RadioButtonsWrapper>
      {formData.touched.profilingRole && formData.errors.profilingRole && (
        <MBanner type="error">{formData.errors.profilingRole}</MBanner>
      )}

      {isError && (
        <MBanner type="error">
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      )}
    </CreateOrganizationStepWrapper>
  )
}

const TextFieldWrapper = styled.div<{ $hidden: boolean }>`
  display: ${({ $hidden }) => ($hidden ? "none" : "block")};

  @container (${breakpoint.smallUp}) {
    display: block;
    visibility: ${({ $hidden }) => ($hidden ? "hidden" : "visible")};
  }
`
